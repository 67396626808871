import { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useNavigate, useParams } from "react-router-dom";
import {
    CompanySubCategory,
    CouponTextTemplate,
    CreateOrUpdateCouponTextTemplateInput,
    GetCouponTextTemplateQuery,
    Region,
    useCreateOrUpdateCouponTextTemplateMutation,
    useCreateOrUpdateRegionMutation,
    useDeleteCouponTextTemplateMutation,
    useDeleteRegionMutation,
    useGetAllCategoriesQuery,
    useGetCouponTextTemplateQuery,
    useGetRegionQuery,
} from "../../../../__generated__/graphql-types";
import ClientButton from "../../web-components/ClientButton";
import { transformDate } from "../../../../helpers";
import { useToasts } from "react-toast-notifications";
import ClientTextInput from "../../web-components/ClientTextInput";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { ApolloError } from "@apollo/client";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Image } from "expo-image";
import { Autocomplete, TextField } from "@mui/material";
import { MySwal } from "../../../../../WebApp";

export const RegionDetail = () => {
    let schema = yup.object({
        region: yup.string().required(),
        description: yup.string().required(),
    });

    let { id } = useParams();
    if (id === "create" || !id) {
        id = "";
    }

    const { loading: regionLoading, data, error: errorTemplates, } = id ? useGetRegionQuery({ variables: { id }, }) : { data: undefined, loading: false, error: {} };

    const [createOrUpdateRegion] = useCreateOrUpdateRegionMutation();
    const [deleteRegion] = useDeleteRegionMutation();
    const navigate = useNavigate();
    const { addToast } = useToasts();

    if (regionLoading) {
        return <span>Wird geladen...</span>;
    }

    return (
        <Formik
            initialValues={data?.getRegion || ({} as Region)}
            validationSchema={schema}
            validateOnMount
            onSubmit={(newForm) => {
                console.log(newForm);
            }}
        >
            {({ isValid, values, errors, setFieldValue }) => (
                <Form>
                    {/* <Text> {JSON.stringify(values, null, "\t")}</Text>
          <Text> {JSON.stringify(errors, null, "\t")}</Text> */}
                    <div className="w-full md:w-1/2">
                        <View className="bg-white p-6 m-3 rounded-xl ">
                            <div className="flex flex-row justify-between items-center">

                                <div className="flex flex-row items-center">
                                    <ClientButton
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                        </svg>
                                        }
                                        mode="secondary"
                                        label=""
                                    ></ClientButton>
                                    <div className="ml-5">
                                        <h2 className="text-2xl font-bold leading-7 text-gray-900">
                                            {id && (<div>"{data?.getRegion?.region}"</div>)}
                                            {!id && <>Neue Region</>}
                                        </h2>
                                        <Text className="text-xs muted text-gray-400 leading-6">
                                            {id && (
                                                <Text>
                                                    Zuletzt aktualisiert:{" "}
                                                    {transformDate(data?.getRegion?.updatedAt)}
                                                </Text>
                                            )}
                                        </Text>
                                    </div>
                                </div>


                                <View className="bg-white p-8 mx-3 rounded-xl">
                                    <View className="flex flex-row">
                                        <ClientButton
                                            onClick={async () => {
                                                try {
                                                    let result = await MySwal.fire({
                                                        title: `Region entfernen? \n`,
                                                        showDenyButton: true,
                                                        confirmButtonText: `Ja, Region entfernen!`,
                                                        denyButtonText: `Abbrechen`,
                                                    });
                                                    if (result.isConfirmed) {
                                                        await deleteRegion({ variables: { id: values._id } });
                                                        navigate(-1);
                                                        addToast("Region wurde erfolgreich entfernt.", {
                                                            appearance: "success",
                                                        });
                                                    }
                                                } catch (error) {
                                                    addToast("FEHLER: " + error, {
                                                        appearance: "error",
                                                    });
                                                }
                                            }}
                                            icon={
                                                <div className="-ml-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </div>
                                            }
                                            mode="secondary"
                                            label=""
                                        ></ClientButton>
                                        <ClientButton
                                            type="submit"
                                            onClick={() => {
                                                if (
                                                    !values?.region ||
                                                    !values?.description 
                                                ) {
                                                    addToast("Bitte alle Felder ausfüllen!", {
                                                        appearance: "warning",
                                                    });
                                                    return;
                                                }
                                                createOrUpdateRegion({
                                                    variables: {
                                                        id: id || "",
                                                        input: {
                                                            region: values.region,
                                                            description: values.description
                                                        },
                                                    },
                                                })
                                                    .then((a) => {
                                                        addToast("Erfolgreich gespeichert", {
                                                            appearance: "success",
                                                        });
                                                        navigate(-1);
                                                    })
                                                    .catch((error) => {
                                                        addToast("FEHLER: " + error, {
                                                            appearance: "error",
                                                        });
                                                    });
                                            }}
                                            disabled={!isValid}
                                            label="Speichern"
                                        ></ClientButton>
                                    </View>
                                </View>
                            </div>

                            <Field
                                component={ClientTextInput}
                                label="Region"
                                mode="text"
                                name="region"
                            ></Field>
                            <Field
                                component={ClientTextInput}
                                label="Beschreibung"
                                mode="text"
                                name="description"
                            ></Field>
                        </View>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default RegionDetail;

import { useState } from "react";
import { TouchableHighlight, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { useNavigate } from "react-router-dom";
import { useGetAllCouponTextTemplatesQuery } from "../../../../__generated__/graphql-types";
import { transformDate } from "../../../../helpers";

export default function CouponTextTemplateList() {
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const navigate = useNavigate();
  const { data, loading, error } = useGetAllCouponTextTemplatesQuery();
  console.log(data);

  if (loading) return <span>Wird geladen...</span>;
  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <View className="ml-4">
      <TouchableHighlight
        onPress={() => navigate("create")}
        className="max-h-32 bg-white p-5 rounded-xl cursor-pointer shadow-sm hover:shadow-lg my-2 w-60 text-center flex items-center"
      >
        <AntDesign name="plus" size={50} color="#14b8a6" />
      </TouchableHighlight>

      <div className="grid grid-cols-2 gap-2">
        {data &&
          data?.getAllCouponTextTemplates?.map((template) => (
            <div
              className="bg-white px-4 py-5 sm:px-6 shadow-sm cursor-pointer hover:shadow-lg rounded-xl"
              onClick={() => navigate(template._id)}
            >
              <div>
                <p className="text-xs text-gray-400 ml-auto text-right">
                  Zuletzt aktualisiert: {transformDate(template.updatedAt)}
                </p>
                <div className="flex space-x-3">
                  <div className="flex-shrink-0">
                    <img
                      className="h-20 w-20 rounded-full"
                      src={template.category?.image || ""}
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-bold text-gray-900">
                      <p className="text-pink-500 text-base">
                        {template.name}
                      </p>
                      <p className="">
                        {template.category?.description}
                      </p>
                    </p>
                    <div className="flex flex-row gap-1">
                      {template.subCategories?.map(subCategory => (
                        <div className="rounded-2xl bg-gray-100 text-pink-500 px-2 py-1 text-xs">{subCategory?.name}</div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="min-w-0 flex-1 mt-4">
                  <p className="text-sm font-bold text-pink-500">
                    {template.header}
                  </p>
                  <p className="text-xs text-gray-500">
                    {template.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>

    </View>
  );
}

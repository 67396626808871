import { useSelector } from "react-redux";
import { selectWebUser } from "../../../../store/web-slices/userSlice.web";
import TruckSVG from "../svg-icons/truck.svg";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Company,
  GetOrderByIdQuery,
  Maybe,
  Order,
  OrderDocuments,
  OrderPosition,
  OrderProduct,
  OrderState,
  SignRequest,
  SignRequestType,
  useCreateOrderDocumentsMutation,
  useCreateOrderMutation,
  useCreateSignRequestMutation,
  useGetAvailableOrderArticlesLazyQuery,
  useGetCompanyWebLazyQuery,
  useGetOrderByIdLazyQuery,
  useUpdateSignRequestMutation,
  useGetSignRequestByOrderIdLazyQuery,
  useGetSignRequestByOrderIdQuery,
  useGetSignRequestLazyQuery,
  useSendOrderConfirmationAndInvoiceMutation,
  useUpdateOrderMutation,
  useUpdateOrderStateMutation,
  useDeleteOrderMutation,
  OrderDurationType,
} from "../../../../__generated__/graphql-types";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import * as yup from "yup";
import { AntDesign, Feather, FontAwesome5, Octicons } from "@expo/vector-icons";
import ClientInputWrapper from "../../web-components/ClientInputWrapper";
import { Field, Form, Formik } from "formik";
import ClientTextInput from "../../web-components/ClientTextInput";
import {
  COUNTRIES,
  POSITIONEN_VERTRETUNGSBERECHTIGT,
} from "../../web-components/dropdown_data";
import ClientButton from "../../web-components/ClientButton";
import {
  base64ToBlob,
  classNames,
  formatNumberToCurrency,
  generateRandomString,
  createPdfViewer,
  transformDate,
} from "../../../../helpers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BillSVG from "../svg-icons/bill.svg";
import ObjectID from "bson-objectid";
import { useToasts } from "react-toast-notifications";
import LoadingScreen from "../../web-components/LoadingScreen";
import NiceModal from "@ebay/nice-modal-react";
import { SignatureQrCodeModal } from "../../web-modals/SignatureQrCode.modal";
import { SignatureReviewModal } from "../../web-modals/SignatureReview.modal";
import OrderStateBadge from "../../web-components/OrderStateBadge";
import OrderDocumentsPreview from "../../web-components/OrderDocumentsPreview";
import { View } from "react-native";
import EntityTimeLine from "../../web-components/EntityTimeLine";
import { MySwal } from "../../../../../WebApp";

export default function AdminOrderScreen() {
  let { currentWebUser } = useSelector(selectWebUser);
  const navigate = useNavigate();

  const orderSchema = yup.object({
    _id: yup.string(),
    orderNumber: yup.string(),
    positions: yup
      .array()
      .of(
        yup.object({
          position: yup.number().min(0),
          quantity: yup.number().min(0),
          priceNet: yup.number().min(0),
          orderProduct: yup
            .object({
              id: yup.number().required(),
              description: yup.string().required(),
              isGift: yup.boolean().required(),
              duration: yup.number().required(),
              durationType: yup.string().nullable(),
              isShipmentArticle: yup.boolean().nullable().optional(),
              priceNet: yup.number().required(),
            })
            .required(),
        })
      )
      .min(0),
    deliveryAddress: yup.object({
      company: yup.string().required(),
      streetName: yup.string().required(),
      streetNameExtra: yup.string(),
      city: yup.string().required(),
      postalCode: yup.string().required(),
      country: yup.string().required(),
    }),
    rechnungsadresse: yup.object({
      firma: yup.string().required(),
      strasse: yup.string().required(),
      postleitzahl: yup.string().required(),
      ort: yup.string().required(),
      land: yup.string().required(),
      email: yup.string().email().required(),
    }),
    beraterUndKundendaten: yup.object({
      gesprochen_mit_anrede: yup.string().required(),
      gesprochen_mit_vorname: yup.string().required(),
      gesprochen_mit_nachname: yup.string().required(),
      unterzeichnet_anrede: yup.string().required(),
      unterzeichnet_vorname: yup.string().required(),
      unterzeichnet_nachname: yup.string().required(),
      unterzeichnet_position: yup.string().required(),
      email_signrequest: yup.string().email(),
    }),
    summary: yup.object({
      netAmount: yup.number().min(0),
      vat: yup.number().min(0),
      grossAmount: yup.number().min(0),
    }),
    signs: yup.object({
      signatureLetters: yup.string(),
      signatureSign: yup.string(),
    }),
    documents: yup.object({
      orderInvoice: yup.string(),
      orderConfirmation: yup.string(),
    }),
    logs: yup.array().default([]),
  });

  const [showPreviewScreen, setShowPreviewScreen] = useState(false);
  const [readonlyMode, setReadonlyMode] = useState(false);
  const [showUploadScreen, setShowUploadScreen] = useState(false);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [loadedOrder, setLoadedOrder] = useState<
    GetOrderByIdQuery["getOrderById"] | undefined
  >(undefined);
  const [orderInvoicePdf, setOrderInvoicePdf] = useState<string | undefined>(
    ""
  );
  const [orderConfirmationPdf, setOrderConfirmationPdf] = useState<
    string | undefined
  >("");
  const [initialValues, setInitialValues] = useState<Order | undefined>(
    undefined
  );
  const [tableColumns, setTableColumns] = useState<
    MRT_ColumnDef<OrderPosition>[] | undefined
  >(undefined);
  const [reviewResult, setReviewResult] = useState<boolean | undefined>(
    undefined
  );
  const [rejectedReason, setRejectedReason] = useState<string | undefined>(
    undefined
  );

  let { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");
  const { addToast } = useToasts();

  const [getCompany, { loading: loadingCompany, error: errorLoadingCompany }] =
    useGetCompanyWebLazyQuery();
  const [getOrderById] = useGetOrderByIdLazyQuery();
  const [getAvailableArticles] = useGetAvailableOrderArticlesLazyQuery();
  const [getSignRequest] = useGetSignRequestLazyQuery();
  const [createOrder] = useCreateOrderMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [updateOrderState] = useUpdateOrderStateMutation();
  const [sendOrderEmail] = useSendOrderConfirmationAndInvoiceMutation();
  const [createOrderDocuments] = useCreateOrderDocumentsMutation();
  const [createSignRequest] = useCreateSignRequestMutation();
  const [updateSignRequest] = useUpdateSignRequestMutation();
  const [getSignRequestByOrderId, { data: signRequest, loading: signRequestLoading }] = useGetSignRequestByOrderIdLazyQuery();

  useEffect(() => {
    setReadonlyMode(
      (loadedOrder &&
        loadedOrder.state !== OrderState.DRAFT &&
        loadedOrder.state !== OrderState.REJECTED) ||
      false
    );

    (async () => {
      console.log("loadedOrder?._id ", loadedOrder?._id);
      if (!loadedOrder?._id) {
        return;
      }
      console.log(loadedOrder);
      await getSignRequestByOrderId({
        variables: {
          orderId: loadedOrder?._id,
        },
      });
    })();
  }, [loadedOrder]);

  useEffect(() => {
    (async () => {
      let initialValues: Order;

      console.log(`useEffect companyId = ${companyId} ; orderId = ${orderId} `);
      if (orderId) {
        console.log(orderId);
        //VIEWMODE OLD ORDER
        let order = (await getOrderById({ variables: { orderId } }))?.data?.getOrderById;
        if (order) {
          setLoadedOrder(order);
          initialValues = order;
          console.log("order ", order);

          if (order.company) {
            let company = (await getCompany({ variables: { id: order.company?._id } }))?.data?.getCompany;
            setCompany(company);
          }
        } else {
          addToast(
            `Bestellung mit der ID ${orderId} konnte nicht gefunden werden!`
          );
        }
      } else if (companyId) {
        let company = (await getCompany({ variables: { id: companyId } }))?.data?.getCompany;
        if (company) {
          setCompany(company);
          initialValues = {
            _id: new ObjectID().toHexString(),
            state: OrderState.DRAFT,
            logs: [],
            orderNumber: generateRandomString(8),
            beraterUndKundendaten: {
              gesprochen_mit_anrede:
                company.beraterKundendatenDetails?.gesprochen_mit_anrede || "",
              gesprochen_mit_vorname:
                company.beraterKundendatenDetails?.gesprochen_mit_vorname || "",
              gesprochen_mit_nachname:
                company.beraterKundendatenDetails?.gesprochen_mit_nachname ||
                "",
              unterzeichnet_anrede:
                company.beraterKundendatenDetails.unterzeichnet_anrede || "",
              unterzeichnet_vorname:
                company.beraterKundendatenDetails?.unterzeichnet_vorname || "",
              unterzeichnet_nachname:
                company.beraterKundendatenDetails?.unterzeichnet_nachname || "",
              unterzeichnet_position:
                company.beraterKundendatenDetails?.unterzeichnet_position || "",
              email_signrequest:
                company.kommunikationsanschriftDetails?.email || "",
            },
            rechnungsadresse: {
              firma: company.betreiberDetails.firma,
              strasse: company.betreiberDetails.strasse,
              ort: company.betreiberDetails.ort,
              postleitzahl: company.betreiberDetails.postleitzahl,
              land: company.betreiberDetails.land,
              email: company.betreiberDetails.email,
            },
            deliveryAddress: {
              company: "",
              streetName: "",
              streetNameExtra: "",
              city: "",
              postalCode: "",
              country: "",
            },
            positions: [
              {
                _id: new ObjectID().toHexString(),
                position: 1,
                orderProduct: null,
                priceNet: null,
                quantity: null,
              },
            ],
            summary: {
              grossAmount: 0,
              netAmount: 0,
              vat: 0,
            },
          };
        } else {
          addToast(
            `Vertrag mit der ID ${companyId} konnte nicht gefunden werden!`
          );
        }
      } else {
        initialValues = {
          _id: new ObjectID().toHexString(),
          state: OrderState.DRAFT,
          logs: [],
          orderNumber: generateRandomString(8),
          beraterUndKundendaten: {},
          rechnungsadresse: {},
          deliveryAddress: {},
          positions: [
            {
              _id: new ObjectID().toHexString(),
              position: 1,
              orderProduct: null,
              priceNet: null,
              quantity: null,
            },
          ],
          summary: {
            grossAmount: 0,
            netAmount: 0,
            vat: 0,
          },
        };
      }

      console.log("initialValues ", initialValues);
      setInitialValues(initialValues);

      let articles = (await getAvailableArticles())?.data
        ?.getAvailableOrderArticles;
      setTableColumns([
        // {
        //   accessorFn: (row) => row.position + ".",
        //   header: "Position",
        //   enableEditing: false,
        //   size: 20,
        // },
        {
          header: "Artikelbezeichnung",
          size: 400,
          Cell: ({ row }) => (
            <div>{row.original.orderProduct?.description}</div>
          ),
          Edit: ({ cell, column, table, row }) => (
            <div>
              {initialValues.state === OrderState.DRAFT ||
                initialValues.state === OrderState.REJECTED ? (
                <Autocomplete
                  size="small"
                  noOptionsText="Artikel nicht gefunden."
                  disablePortal={false}
                  options={articles}
                  onChange={(event, value) => {
                    row._valuesCache["orderProduct"] = value;
                    row.original.priceNet = value?.priceNet;
                    table.setEditingCell(cell); //ruft den callback onEditingCellChange={(event) => { ... } in der tabelle auf.
                  }}
                  defaultValue={row.original.orderProduct || null}
                  getOptionLabel={(option) => option.description}
                  renderInput={(params) => (
                    <TextField {...params} label="Artikel" />
                  )}
                />
              ) : null}
            </div>
          ),
        },
        {
          accessorFn: (row) => row.quantity,
          size: 50,
          header: "Anzahl",
        },
        {
          accessorFn: (row) => row.priceNet,
          size: 100,
          header: "Einzelpreis Netto €",
          muiTableBodyCellEditTextFieldProps: ({ cell, row }) => ({
            type: "text",
            required: true,
            defaultValue: row.original.orderProduct?.priceNet,
            disabled: row.original.orderProduct?.isGift,
            placeholder: row.original.orderProduct?.isGift
              ? "Gratis Werbegeschenk"
              : "Einzelpreis Netto €",
          }),
        },
      ]);
    })();
  }, [companyId, orderId]);

  const calculateSumNet = (
    positions:
      | Maybe<OrderPosition[]>
      | {
        position: number;
        description: string;
        priceNet: null;
        quantity: null;
      }[]
      | undefined
  ): number => {
    if (!positions || !positions?.length) {
      return 0; // Rückgabe 0, wenn keine Positionen vorhanden sind
    }

    // Durchlaufe die Positionen und berechne die Summe
    let gesamtsumme = 0;
    for (const position of positions) {
      if (position && position.priceNet && position.quantity) {
        gesamtsumme += position.priceNet * position.quantity;
      }
    }

    return +gesamtsumme.toFixed(2);
  };

  const createOrderDocumentsInternal = async (
    orderId: string,
    upload: boolean
  ) => {
    try {
      let result = await createOrderDocuments({
        variables: {
          createOrderDocumentsInput: {
            orderId: orderId,
            upload: upload,
          },
        },
      });
      setOrderConfirmationPdf(result.data?.createOrderDocuments.orderConfirmation);
      setOrderInvoicePdf(result.data?.createOrderDocuments.orderInvoice);
    } catch (orderError) {
      throw new Error("Dokumente konnten nicht erstellt werden! " + orderError);
    }
  };

  if (loadingCompany || !tableColumns || !initialValues) {
    return <LoadingScreen>Bestellung wird geladen...</LoadingScreen>;
  }

  if (errorLoadingCompany && errorLoadingCompany.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {errorLoadingCompany.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }
  return (
    <Formik
      initialValues={initialValues || {}}
      validationSchema={orderSchema}
      validateOnMount
      onSubmit={async (bestellformular) => { }}
    >
      {({ isValid, values, errors, setFieldValue, setValues }) => (
        <Form>
           {/* {JSON.stringify(values, null, 2)}
          {JSON.stringify(errors, null, 2)}  */}

          <>
            <div className="bg-white p-6 mx-3 rounded-xl mt-2 justify-between flex">
              <div>
                {loadedOrder && (
                  <div className="mb-2">
                    <OrderStateBadge
                      state={loadedOrder.state}
                    ></OrderStateBadge>
                  </div>
                )}
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  {loadedOrder ? `Bestellung #${loadedOrder?.orderNumber} vom ${transformDate(loadedOrder?.createdAt)}` : `Neue Bestellung ${values.orderNumber}`}
                </h2>
                <div>
                  <div className="text-xs text-gray-400 leading-6">
                    Mitarbeiter ID: {currentWebUser?._id}
                  </div>
                  <div className="text-xs text-gray-400 ">
                    Mitarbeiter Name:{" "}
                    {currentWebUser?.firstName + " " + currentWebUser?.lastName}
                  </div>
                </div>
              </div>

              <div className="mt-5 flex lg:ml-4 lg:mt-0 my-auto flex-wrap gap-2 items-end">

                {((loadedOrder?.state === OrderState.DRAFT || loadedOrder?.state === OrderState.REJECTED) && !showPreviewScreen && !showUploadScreen) && (
                  <div className="ml-auto mt-1">
                    <ClientButton
                      icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ec4899" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                      }
                      onClick={async () => {
                        try {
                          let swalResult = await MySwal.fire({
                            title: `Bestellung löschen? \n`,
                            html: `Die Bestellung wird unwiederruflich gelöscht. Bist du dir sicher?<br><br>Bitte beachte, dieser Vorgang kann nicht rückgängig gemacht werden!`,
                            showDenyButton: true,
                            width: 700,
                            confirmButtonText: `Ja, Bestellung löschen!`,
                            denyButtonText: `Abbrechen`,
                          });

                          if (!swalResult.isConfirmed) {
                            return;
                          }

                          await deleteOrder({
                            variables: {
                              orderId: loadedOrder?._id
                            }
                          });

                          addToast("Bestellung wurde erfolgreich gelöscht.", { appearance: "success" });
                          navigate("/client/orders/all-orders");
                        } catch (error) {
                          throw new Error('FEHLER: ' + error);
                        }
                      }}
                      label={"Bestellung löschen"}
                      mode="secondary"
                      iconLeft={true}
                    ></ClientButton>
                  </div>
                )}

                {((loadedOrder?.state === OrderState.WAIT_CUSTOMER_REACTION || loadedOrder?.state === OrderState.NOT_PAYED) && !showPreviewScreen && !showUploadScreen) && (
                  <div className="ml-auto mt-1">
                    <ClientButton
                      icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ec4899" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                      </svg>
                      }
                      onClick={async () => {
                        let swalResult = await MySwal.fire({
                          title: `Bestellung auf Entwurf setzen? \n`,
                          html: `Die Bestellung wird auf den Zustand 'ENTWURF' gesetzt. Bist du dir sicher?<br><br>Bitte beachte, dieser Vorgang kann nicht rückgängig gemacht werden!`,
                          showDenyButton: true,
                          width: 700,
                          confirmButtonText: `Ja, auf Entwurf setzen!`,
                          denyButtonText: `Abbrechen`,
                        });

                        if (!swalResult.isConfirmed) {
                          return;
                        }

                        await updateOrderState({
                          variables: {
                            orderId: loadedOrder!._id!,
                            state: OrderState.DRAFT,
                          },
                        });

                        navigate(0);
                      }}
                      label={"Auf Entwurf setzen"}
                      mode="secondary"
                      iconLeft={true}
                    ></ClientButton>
                  </div>
                )}

                {company && (
                  <div>
                    <ClientButton
                      onClick={async () => {
                        navigate(`/client/overview/${company._id}`);
                      }}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>
                      }
                      iconLeft
                      mode="secondary"
                      label="Vertrag Öffnen"
                    ></ClientButton>
                  </div>
                )}
                {!readonlyMode && !showPreviewScreen && !showUploadScreen && (
                  <div>
                    <ClientButton
                      type="submit"
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                        </svg>
                      }
                      onClick={async () => {
                        try {
                          let order: Order;
                          try {
                            if (loadedOrder) {
                              let updatedOrder = await updateOrder({
                                variables: {
                                  id: loadedOrder._id,
                                  updateOrderInput: {
                                    ...values,
                                  },
                                },
                              });
                              order = updatedOrder?.data?.updateOrder as Order;
                            } else {
                              let createdOrder = await createOrder({
                                variables: {
                                  createOrderInput: {
                                    ...values,
                                    company,
                                    signs: undefined,
                                  },
                                },
                              });
                              order = createdOrder?.data?.createOrder as Order;
                            }
                            setLoadedOrder(order);
                          } catch (orderError) {
                            throw new Error(
                              "Bestellung konnte nicht erstellt werden! " +
                              orderError
                            );
                          }
                          await createOrderDocumentsInternal(order._id, false);
                          setShowPreviewScreen(true);
                        } catch (error) {
                          addToast("FEHLER: " + error, { appearance: "error" });
                        }
                      }}
                      disabled={!isValid}
                      label="Bestellung überprüfen"
                    ></ClientButton>
                  </div>
                )}

                {showPreviewScreen && (
                  <div className="flex">
                    <div className="ml-5 mt-1 text-center">
                      {!showUploadScreen && (
                        <>
                          <ClientButton
                            mode="secondary"
                            onClick={async () => {
                              setShowPreviewScreen(false);
                              navigate(`/client/orders/${loadedOrder?._id}`);
                            }}
                            label="Zurück"
                          ></ClientButton>

                          <ClientButton
                            onClick={async () => {
                              try {
                                let swalResult = await MySwal.fire({
                                  title: `Unterschrift anfordern \n`,
                                  html: `Ein Link wird an die E-Mail-Adresse <strong>${values.beraterUndKundendaten?.email_signrequest}</strong> gesendet, über den der Kunde aufgefordert wird, die Unterschrift bereitzustellen.
                                <br><br>Bitte beachte, dieser Vorgang kann nicht rückgängig gemacht werden!`,
                                  showDenyButton: true,
                                  width: 700,
                                  confirmButtonText: `Ja, E-Mail senden!`,
                                  denyButtonText: `Abbrechen`,
                                });

                                if (!swalResult.isConfirmed) {
                                  return;
                                }

                                if (
                                  !values.beraterUndKundendaten?.email_signrequest
                                ) {
                                  addToast(
                                    "E-Mail für Kommunkation wurde nicht gefunden. Link kann nicht gesendet werden.",
                                    { appearance: "warning" }
                                  );
                                  return;
                                }

                                values.logs = [];
                                await createOrderDocuments({
                                  variables: {
                                    createOrderDocumentsInput: {
                                      orderId: loadedOrder?._id,
                                      upload: true,
                                    },
                                  },
                                });
                                await createSignRequest({
                                  variables: {
                                    createSignRequestInput: {
                                      order: values,
                                      requestType: SignRequestType.Order,
                                      email:
                                        values.beraterUndKundendaten
                                          ?.email_signrequest,
                                    },
                                  },
                                });
                                navigate(
                                  `/client/orders/signrequest-confirmation?email=${values.beraterUndKundendaten?.email_signrequest}&orderNumber=${values.orderNumber}`
                                );
                              } catch (error) {
                                addToast("FEHLER: " + error, {
                                  appearance: "error",
                                });
                              }
                            }}
                            disabled={
                              !isValid ||
                              !values.beraterUndKundendaten?.email_signrequest ||
                              !orderInvoicePdf ||
                              !orderConfirmationPdf
                            }
                            icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                            </svg>
                            }
                            mode="secondary"
                            label={`${values.beraterUndKundendaten?.email_signrequest ? `Unterschrift anfordern - ${values.beraterUndKundendaten?.email_signrequest}` : "Unterschrift anfordern"}`}
                          ></ClientButton>

                          <ClientButton
                            onClick={async () => {
                              try {
                                if (!loadedOrder) {
                                  addToast(
                                    "Es wurde keine Bestellung gefunden!. Bitte versuche es erneut.",
                                    { appearance: "info" }
                                  );
                                  return;
                                }

                                let signatureQrCodeModalResult =
                                  (await NiceModal.show(SignatureQrCodeModal, {
                                    requestType: SignRequestType.OrderOnSide,
                                    order: values,
                                  })) as {
                                    resolved: boolean;
                                    signRequestId: string;
                                  };

                                if (!signatureQrCodeModalResult?.resolved) {
                                  return;
                                }

                                let signRequest = (
                                  await getSignRequest({
                                    variables: {
                                      getSignRequestInput: {
                                        requestId:
                                          signatureQrCodeModalResult.signRequestId,
                                      },
                                    },
                                  })
                                )?.data?.getSignRequest;

                                if (!signRequest) {
                                  addToast(
                                    "FEHLER: Anfrage konnte nicht bearbeitet werden!. Bitte versuche es erneut!"
                                  );
                                  return;
                                }

                                let signatureReviewModelResult =
                                  (await NiceModal.show(SignatureReviewModal, {
                                    signRequest: signRequest as SignRequest,
                                  })) as {
                                    resolved: boolean;
                                    signsAccepted: boolean;
                                  };

                                if (!signatureReviewModelResult.resolved) {
                                  return;
                                }

                                if (signatureReviewModelResult.signsAccepted) {
                                  setFieldValue("signs", signRequest.signs);
                                  await updateOrder({
                                    variables: {
                                      id: loadedOrder._id,
                                      updateOrderInput: {
                                        signs: signRequest.signs,
                                      },
                                    },
                                  });

                                  await updateSignRequest({
                                    variables: {
                                      id: signRequest._id,
                                      updateSignRequestInput: {
                                        signsAccepted: true,
                                      },
                                    },
                                  });

                                  await createOrderDocumentsInternal(
                                    loadedOrder._id,
                                    false
                                  );
                                  setShowUploadScreen(true);
                                } else {
                                  await updateSignRequest({
                                    variables: {
                                      id: signRequest._id,
                                      updateSignRequestInput: {
                                        signsAccepted: false,
                                      },
                                    },
                                  });
                                  addToast(
                                    "Du hast die Unterschriften abgelehnt. Beantrage die Unterschriften erneut per QR-Code oder E-Mail.",
                                    { appearance: "info" }
                                  );
                                }
                              } catch (error) {
                                addToast(" ---- " + error, {
                                  appearance: "error",
                                });
                              }
                            }}
                            disabled={
                              !isValid ||
                              !orderInvoicePdf ||
                              !orderConfirmationPdf
                            }
                            icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>
                            }
                            label="Jetzt unterschreiben"
                          ></ClientButton>
                        </>
                      )}

                      {showUploadScreen && (
                        <ClientButton
                          onClick={async () => {
                            try {
                              await createOrderDocumentsInternal(
                                loadedOrder!._id,
                                true
                              );
                              await updateOrderState({
                                variables: {
                                  orderId: loadedOrder!._id!,
                                  state: OrderState.IN_REVIEW,
                                },
                              });

                              addToast("Bestellung wird geprüft.", {
                                appearance: "success",
                              });
                              navigate("/client/orders/all-orders");
                            } catch (error) {
                              addToast("FEHLER: " + error, {
                                appearance: "error",
                              });
                            }
                          }}
                          label="Bestellung hochladen"
                        ></ClientButton>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {showPreviewScreen && (
              <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2">
                <div className="p-8 m-3 bg-white rounded-lg">
                  <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight mb-2">
                    Vorschau - Rechnung
                  </div>
                  {orderInvoicePdf ? (
                    <div>{createPdfViewer(orderInvoicePdf, false)}</div>
                  ) : (
                    <LoadingScreen>Rechnung wird erstellt...</LoadingScreen>
                  )}
                </div>
                <div className="p-8 m-3 bg-white rounded-lg">
                  <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight mb-2">
                    Vorschau - Bestellformular
                  </div>
                  {orderConfirmationPdf ? (
                    <div>{createPdfViewer(orderConfirmationPdf, false)}</div>
                  ) : (
                    <LoadingScreen>
                      Bestellformular wird erstellt...
                    </LoadingScreen>
                  )}
                </div>
              </div>
            )}

            {!showPreviewScreen && (
              <div className="mx-3">
                <dl className="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
                  {/* Vorhandner Signrequest */}
                  {loadedOrder &&
                    (loadedOrder.state === OrderState.WAIT_CUSTOMER_REACTION ||
                      loadedOrder.state === OrderState.IN_REVIEW) &&
                    signRequest &&
                    signRequest.getSignRequestByOrderId?.signsAccepted === null &&
                    signRequest.getSignRequestByOrderId?.requestType === SignRequestType.Order && (
                      <div>
                        <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-sm rounded-lg mb-2">
                          <div className="flex flex-row ml-3">
                            <dt>
                              <div className="shadow-sm shadow-pink-100 rounded-xl mr-4 p-4">
                                <FontAwesome5
                                  name="signature"
                                  size={24}
                                  color="#E43D95"
                                />
                              </div>
                            </dt>
                            <dd className="text-xl font-bold text-gray-900 my-auto">
                              Unterschrift
                            </dd>
                          </div>
                          <div className="p-4">
                            <div className="flex flex-row justify-between">
                              <div className="text-gray-400 text-sm font-light">
                                Anfrage geschickt am
                              </div>
                              <div className="text-sm font-medium">
                                {transformDate(
                                  signRequest.getSignRequestByOrderId?.createdAt
                                )}
                              </div>
                            </div>
                            <div className="border-b my-2"></div>
                            <div className="flex flex-row justify-between">
                              <div className="text-gray-400 text-sm font-light">
                                E-Mail
                              </div>
                              <div className="text-sm font-medium">
                                {signRequest.getSignRequestByOrderId.email}
                              </div>
                            </div>
                            <div className="border-b my-2"></div>
                            <div className="flex flex-row justify-between">
                              <div className="text-gray-400 text-sm font-light">
                                Erinnerungen
                              </div>
                              <div className="text-sm font-medium">
                                {
                                  signRequest.getSignRequestByOrderId
                                    .reminderMailCounter
                                }
                              </div>
                            </div>
                            {signRequest.getSignRequestByOrderId
                              .reminderMailCounter > 0 && (
                                <>
                                  <div className="border-b my-2"></div>
                                  <div className="flex flex-row justify-between">
                                    <div className="text-gray-400 text-sm font-light">
                                      Letzte Erinnerung
                                    </div>
                                    <div className="text-sm font-medium">
                                      {transformDate(
                                        signRequest.getSignRequestByOrderId
                                          .reminderSendedAt
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            <div className="border-b my-2"></div>
                            <div className="flex flex-row justify-between">
                              <div className="text-gray-400 text-sm font-light">
                                Unterschrieben?
                              </div>
                              {signRequest.getSignRequestByOrderId.signed &&
                                signRequest.getSignRequestByOrderId.signedAt ? (
                                <div className="p-1.5 bg-teal-600 text-white font-semibold rounded-md text-xs uppercase tracking-wide text-center">
                                  Ja am,{" "}
                                  {transformDate(
                                    signRequest.getSignRequestByOrderId.signedAt
                                  )}
                                </div>
                              ) : (
                                <div className="p-1.5 bg-red-400 text-white font-semibold rounded-md text-xs uppercase tracking-wide text-center">
                                  Nein
                                </div>
                              )}
                            </div>
                            {signRequest.getSignRequestByOrderId.signed && (
                              <>
                                <div className="border-b my-2"></div>
                                <div className="flex flex-row justify-between">
                                  <div className="text-gray-400 text-sm font-light">
                                    Prüfung
                                  </div>
                                  <a
                                    className="text-sm font-bold underline text-pink-500 cursor-pointer"
                                    href="/client/reactions/sign-requests-reactions"
                                  >
                                    Unterschriften überprüfen
                                  </a>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                  {/* In Überprüfung: Bitte auswählen */}
                  {loadedOrder &&
                    (loadedOrder.state === OrderState.IN_REVIEW ||
                      loadedOrder.state === OrderState.REJECTED) &&
                    signRequest?.getSignRequestByOrderId?.signed &&
                    signRequest?.getSignRequestByOrderId?.signsAccepted && (
                      <div>
                        <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-sm rounded-lg mb-2">
                          <div className="flex flex-row ml-3">
                            <dt>
                              <div className="shadow-sm shadow-pink-100 rounded-xl mr-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#E43D95"
                                  className="w-7 h-7"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                  />
                                </svg>
                              </div>
                            </dt>
                            <dd className="text-xl font-bold text-gray-900 my-auto">
                              Überprüfung
                            </dd>
                          </div>

                          <div className="flex flex-col">
                            <dd className="mt-4">
                              <label className=" mt-5">
                                {loadedOrder.state === OrderState.IN_REVIEW && (
                                  <div className="tracking-wide text-gray-700 text-xs font-bold ">
                                    Bitte auswählen:{" "}
                                  </div>
                                )}
                                {loadedOrder.state === OrderState.REJECTED && (
                                  <div className="rounded-md bg-red-50 p-4">
                                    <div className="flex">
                                      <div className="flex-shrink-0">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="#b91c1c"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </div>
                                      <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-700">
                                          Deine Bestellung wurde vom
                                          Administrator abgelehnt!
                                        </h3>
                                        <div className="mt-2 text-sm text-red-700">
                                          <div>
                                            <span className="font-semibold">
                                              Grund:{" "}
                                            </span>
                                            {loadedOrder.stateInformation}
                                          </div>
                                          <div className="mt-2 text-xs">
                                            <span className="font-semibold">
                                              Hinweis:{" "}
                                            </span>
                                            Korrigiere die Bestelldaten. Die
                                            Unterschriften wurden entfernt und
                                            müssen neu angefordert werden.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </label>
                              {loadedOrder.state === OrderState.IN_REVIEW && (
                                <>
                                  <div className="divide-x flex flex-row text-center bg-white rounded-lg shadow divide-gray-200 mt-2">
                                    <div
                                      onClick={() => {
                                        if (
                                          loadedOrder.state ===
                                          OrderState.IN_REVIEW
                                        ) {
                                          setReviewResult(true);
                                        }
                                      }}
                                      className={classNames(
                                        loadedOrder.state ===
                                          OrderState.IN_REVIEW
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed",
                                        "w-0 flex-1 rounded-l-lg",
                                        reviewResult ? "bg-teal-500/60" : ""
                                      )}
                                    >
                                      <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent  hover:text-gray-500">
                                        <Octicons
                                          name="check"
                                          size={21}
                                          color={
                                            reviewResult ? "white" : "#14b8a6"
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className={classNames(
                                        "w-0 flex-1 cursor-not-allowed",
                                        reviewResult === undefined
                                          ? "bg-zinc-200"
                                          : ""
                                      )}
                                    >
                                      <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent  hover:text-gray-500">
                                        <Feather
                                          name="slash"
                                          size={21}
                                          color={
                                            reviewResult === undefined
                                              ? "gray"
                                              : "#e4e4e7"
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className={classNames(
                                        loadedOrder.state ===
                                          OrderState.IN_REVIEW
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed",
                                        "w-0 flex-1 rounded-r-lg",
                                        reviewResult === false
                                          ? "bg-red-500/70"
                                          : ""
                                      )}
                                      onClick={() => {
                                        if (
                                          loadedOrder.state ===
                                          OrderState.IN_REVIEW
                                        ) {
                                          setReviewResult(false);
                                        }
                                      }}
                                    >
                                      <div className="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent hover:text-gray-500">
                                        <AntDesign
                                          name="close"
                                          size={21}
                                          color={
                                            reviewResult === false
                                              ? "white"
                                              : "#f43f5e"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {reviewResult === true && (
                                    <div className="flex items-start space-x-4 mt-2">
                                      <div className="min-w-0 flex-1">
                                        <div className="flex-shrink-0">
                                          <ClientButton
                                            onClick={async () => {
                                              try {
                                                await sendOrderEmail({
                                                  variables: {
                                                    input: {
                                                      orderId: loadedOrder._id,
                                                    },
                                                  },
                                                });
                                                await updateOrderState({
                                                  variables: {
                                                    orderId: loadedOrder._id,
                                                    state: OrderState.NOT_PAYED,
                                                  },
                                                });
                                                addToast(
                                                  "Vertrag wurde genehmigt! Rechnung wurde per E-Mail verschickt.",
                                                  { appearance: "success" }
                                                );
                                                navigate(-1);
                                              } catch (error) {
                                                addToast(
                                                  "FEHLER: Bestellung konnte nicht genehmigt werden! Rechnung wurde nicht an den Kunden verschickt." +
                                                  error
                                                );
                                              }
                                            }}
                                            mode="primary"
                                            label="Bestellung Genehmigen"
                                            className="inline-flex uppercase items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-teal-600/70 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                          ></ClientButton>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {reviewResult === false && (
                                    <div className="flex items-start space-x-4">
                                      <div className="min-w-0 flex-1">
                                        <div className="border border-gray-200 focus:border-pink-500 mt-2">
                                          <textarea
                                            rows={9}
                                            disabled={
                                              loadedOrder.state !==
                                              OrderState.IN_REVIEW
                                            }
                                            name="rejected_commtent"
                                            id="rejected_commtent"
                                            className="block w-full border-0 border-b border-transparent focus:outline-none p-3 pb-2 focus:ring-0 focus:border-pink-500 sm:text-sm"
                                            placeholder="Grund für die Ablehnung..."
                                            defaultValue={
                                              loadedOrder.stateInformation || ""
                                            }
                                            onChange={(e) =>
                                              setRejectedReason(
                                                (e.currentTarget as any).value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="pt-2">
                                          {loadedOrder.state ===
                                            OrderState.IN_REVIEW && (
                                              <ClientButton
                                                disabled={!rejectedReason?.length}
                                                onClick={async () => {
                                                  try {
                                                    await updateOrderState({
                                                      variables: {
                                                        orderId: loadedOrder._id,
                                                        state:
                                                          OrderState.REJECTED,
                                                        stateInformation:
                                                          rejectedReason,
                                                      },
                                                    });
                                                    await updateOrder({
                                                      variables: {
                                                        id: loadedOrder._id,
                                                        updateOrderInput: {
                                                          signs: {
                                                            signatureSign: null,
                                                            signatureLetters:
                                                              null,
                                                          },
                                                        },
                                                      },
                                                    });
                                                    addToast(
                                                      "Bestellung wurde abgelehnt.",
                                                      { appearance: "error" }
                                                    );
                                                    navigate(-1);
                                                  } catch (error) {
                                                    addToast(
                                                      "FEHLER: Bestellung konnte nicht genehmigt werden!" +
                                                      error
                                                    );
                                                  }
                                                }}
                                                mode="primary"
                                                label="Bestellung Ablehnen"
                                                className="inline-flex uppercase items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-teal-600/70 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                              ></ClientButton>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </dd>
                          </div>
                        </div>
                      </div>
                    )}

                  <div>
                    <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-sm rounded-lg  mb-2">
                      <div className="flex flex-row ml-3">
                        <dt>
                          <div className="shadow-sm shadow-pink-100 rounded-xl mr-4">
                            <BillSVG></BillSVG>
                          </div>
                        </dt>
                        <dd className="text-xl font-bold text-gray-900 my-auto">
                          Rechnungsadresse
                        </dd>
                      </div>

                      <div className="flex flex-col">
                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="rechnungsadresse.firma"
                            label="Firma"
                            grow
                            onClick={(value: string) =>
                              setFieldValue("firma", value)
                            }
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                        </ClientInputWrapper>

                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="rechnungsadresse.strasse"
                            label="Strasse"
                            grow
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                        </ClientInputWrapper>

                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="rechnungsadresse.postleitzahl"
                            label="Plz"
                            shrink
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>

                          <Field
                            component={ClientTextInput}
                            name="rechnungsadresse.ort"
                            label="Ort"
                            shrink
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                        </ClientInputWrapper>
                        <Field
                          component={ClientTextInput}
                          disabled={readonlyMode}
                          name="rechnungsadresse.land"
                          label="Land"
                          mode="textAndDropdown"
                          onClick={(value: string) =>
                            setFieldValue("rechnungsadresse.land", value)
                          }
                          dropdownData={COUNTRIES}
                          addPleaseSelectOption={false}
                        ></Field>
                        <Field
                          component={ClientTextInput}
                          name="rechnungsadresse.email"
                          label="E-Mail"
                          grow
                          disabled={readonlyMode}
                          addPleaseSelectOption={false}
                        ></Field>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-sm rounded-lg">
                      <div className="flex flex-row ml-3">
                        <dt>
                          <div
                            className="shadow-sm shadow-pink-100 rounded-xl mr-4"
                            onClick={() => {
                              console.log(values);
                              setValues({
                                orderNumber: values.orderNumber,
                                _id: new ObjectID().toHexString(),
                                state: OrderState.DRAFT,
                                beraterUndKundendaten: {
                                  gesprochen_mit_vorname: "JF  Software und",
                                  gesprochen_mit_nachname: "eCom  GmbH",
                                  unterzeichnet_vorname: "JF  Software und",
                                  unterzeichnet_nachname: "eCom  GmbH",
                                  email_signrequest: "info@jf-software-ecom.de",
                                  gesprochen_mit_anrede: "Frau",
                                  unterzeichnet_anrede: "Frau",
                                  unterzeichnet_position: "Chef",
                                },
                                rechnungsadresse: {
                                  firma: "JF SOFTWARE & ECOM GMBH",
                                  strasse: "Mittenwalderstraße 5",
                                  postleitzahl: "82467",
                                  ort: "Garmisch-Partenkirchen",
                                  land: "Deutschland",
                                  email: "jamezfatout-business@gmx.de",
                                },
                                deliveryAddress: {
                                  company: "JF Software & eCom GmbH",
                                  streetName: "Mittenwalderstraße 5",
                                  streetNameExtra: "Mittenwalderstraße 5",
                                  postalCode: "82467",
                                  city: "Garmisch-Partenkirchen",
                                  country: "Deutschland",
                                },
                                logs: [],
                                positions: [
                                  {
                                    _id: new ObjectID().toHexString(),
                                    position: 1,
                                    orderProduct: {
                                      id: 1,
                                      description: "12 Monats Abo (Jahresabo)",
                                      duration: 12,
                                      durationType: OrderDurationType.Months,
                                      isGift: false,
                                      priceNet: 50.42,
                                    },
                                    priceNet: 50.42,
                                    quantity: 10,
                                  },
                                  {
                                    _id: new ObjectID().toHexString(),
                                    position: 2,
                                    orderProduct: {
                                      id: 2,
                                      description: 'Versandkosten',
                                      duration: 0,
                                      isGift: false,
                                      isShipmentArticle: true,
                                      priceNet: 4.99,
                                    },
                                    priceNet: 4.99,
                                    quantity: 1,
                                  },
                                ],
                                summary: {
                                  netAmount: 509.19,
                                  vat: 96.75,
                                  grossAmount: 605.94,
                                },
                              });
                            }}
                          >
                            <TruckSVG></TruckSVG>
                          </div>
                        </dt>

                        <dd className="text-xl font-bold text-gray-900 my-auto">
                          Lieferadresse
                        </dd>
                      </div>

                      <div className="flex flex-col">
                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="deliveryAddress.company"
                            label="Firma"
                            grow
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                        </ClientInputWrapper>

                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="deliveryAddress.streetName"
                            label="Strasse"
                            grow
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                        </ClientInputWrapper>

                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="deliveryAddress.streetNameExtra"
                            label="Strassenzusatz"
                            grow
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                        </ClientInputWrapper>

                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="deliveryAddress.postalCode"
                            label="Plz"
                            shrink
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>

                          <Field
                            component={ClientTextInput}
                            name="deliveryAddress.city"
                            label="Ort"
                            grow
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                        </ClientInputWrapper>
                        <Field
                          component={ClientTextInput}
                          disabled={readonlyMode}
                          name="deliveryAddress.country"
                          label="Land"
                          mode="textAndDropdown"
                          onClick={(value: string) =>
                            setFieldValue("deliveryAddress.country", value)
                          }
                          dropdownData={COUNTRIES}
                          addPleaseSelectOption={false}
                        ></Field>
                      </div>
                    </div>
                  </div>

                  {(loadedOrder?.state === OrderState.NOT_PAYED ||
                    loadedOrder?.state === OrderState.PAYED_NOT_PRINTED ||
                    loadedOrder?.state === OrderState.PAYED_SHIPPED ||
                    loadedOrder?.state === OrderState.IN_REVIEW ||
                    (loadedOrder?.state === OrderState.REJECTED &&
                      loadedOrder?.documents)) && (
                      <div
                        className={classNames(
                          loadedOrder.state === OrderState.IN_REVIEW ||
                            loadedOrder.state === OrderState.REJECTED
                            ? ""
                            : "col-span-2"
                        )}
                      >
                        <OrderDocumentsPreview
                          orderDocuments={
                            loadedOrder?.documents as OrderDocuments
                          }
                          height={495}
                        ></OrderDocumentsPreview>
                      </div>
                    )}

                  <div className="col-span-2">
                    <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-sm rounded-lg ">
                      <div className="flex flex-row ml-3 mb-1">
                        <dt>
                          <div className="shadow-sm shadow-pink-100 p-3 rounded-xl mr-4">
                            <Octicons
                              name="briefcase"
                              size={32}
                              color="#E43D95"
                            />
                          </div>
                        </dt>
                        <dd className="text-xl font-bold text-gray-900 my-auto">
                          Berater & Kundendaten
                        </dd>
                      </div>
                      <ClientInputWrapper>
                        <ClientTextInput
                          defaultValue={
                            company?.kundennummer || "Kein Vertrag vorhanden."
                          }
                          label="Kundennummer"
                          grow
                          disabled
                          addPleaseSelectOption={false}
                        ></ClientTextInput>
                      </ClientInputWrapper>

                      <div className="flex flex-col">
                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="beraterUndKundendaten.gesprochen_mit_anrede"
                            label="Gesprochen mit (Anrede)"
                            onClick={(value: string) =>
                              setFieldValue(
                                "beraterUndKundendaten.gesprochen_mit_anrede",
                                value
                              )
                            }
                            disabled={readonlyMode}
                            dropdownData={
                              [
                                // props.form.betreiberDetails?.vertretung_anrede,
                                // props.form.kommunikationsanschriftDetails.anrede,
                              ]
                            }
                            addPleaseSelectOption={false}
                          ></Field>
                          <Field
                            component={ClientTextInput}
                            name="beraterUndKundendaten.gesprochen_mit_vorname"
                            label="Gesprochen mit (Vorname)"
                            grow
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                          <Field
                            component={ClientTextInput}
                            name="beraterUndKundendaten.gesprochen_mit_nachname"
                            label="Gesprochen mit (Nachname)"
                            grow
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                        </ClientInputWrapper>

                        <ClientInputWrapper>
                          <Field
                            component={ClientTextInput}
                            name="beraterUndKundendaten.unterzeichnet_anrede"
                            label="Unterzeichnet von (Anrede)"
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                          <Field
                            component={ClientTextInput}
                            name="beraterUndKundendaten.unterzeichnet_vorname"
                            label="Unterzeichnet von (Vorname)"
                            grow
                            disabled={readonlyMode}
                            addPleaseSelectOption={false}
                          ></Field>
                          <Field
                            component={ClientTextInput}
                            name="beraterUndKundendaten.unterzeichnet_nachname"
                            label="Unterzeichnet von (Nachname)"
                            grow
                            disabled={readonlyMode}
                          ></Field>
                        </ClientInputWrapper>
                        <Field
                          component={ClientTextInput}
                          name="beraterUndKundendaten.unterzeichnet_position"
                          label="Unterzeichnet von (Position)"
                          grow
                          disabled={readonlyMode}
                          addPleaseSelectOption={false}
                        ></Field>
                        <Field
                          component={ClientTextInput}
                          name="beraterUndKundendaten.email_signrequest"
                          label="E-Mail Unterschrift Link"
                          grow
                          disabled={readonlyMode}
                          dropdownData={POSITIONEN_VERTRETUNGSBERECHTIGT}
                          addPleaseSelectOption={false}
                        ></Field>
                      </div>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      !loadedOrder || loadedOrder?.state === OrderState.DRAFT
                        ? "col-span-4"
                        : "col-span-2"
                    )}
                  >
                    {/* {JSON.stringify(values, null, "\t")} 
                    {JSON.stringify(errors, null, "\t")} */}
                    <MaterialReactTable
                      columns={tableColumns}
                      data={values?.positions || []}
                      enableEditing={!readonlyMode}
                      editingMode="table"
                      onEditingCellChange={(cell) => {
                        // wird aufgerufen wenn das custom autocomplete Artikelbezeichnung ausgewählt wird
                        if (!cell) {
                          return;
                        }
                        let row = cell.row;
                        let rowIndex = row.index;
                        let temp = [...(values.positions as OrderPosition[])];

                        let selectedOrderProduct = row._valuesCache.orderProduct as OrderProduct;
                        temp[rowIndex].orderProduct = selectedOrderProduct;

                        if (selectedOrderProduct.isGift) {
                          temp[rowIndex].priceNet = 0;
                        } else {
                          temp[rowIndex].priceNet = selectedOrderProduct.priceNet;
                        }
                        setFieldValue("positions", temp);
                      }}
                      muiTableBodyCellEditTextFieldProps={({
                        cell,
                        table,
                        row,
                        column,
                      }) => ({
                        onBlur: (event) => {
                          console.log("onBlur ", event);
                          let columnFnKey = "";
                          switch (cell.column.id) {
                            case "Artikelbezeichnung":
                              columnFnKey = "orderProduct";
                              break;
                            case "Anzahl":
                              columnFnKey = "quantity";
                              break;
                            case "Einzelpreis Netto €":
                              columnFnKey = "priceNet";
                              break;
                          }

                          let inputText: string | number = (event.target as any)
                            .value as string;

                          if (columnFnKey === "priceNet") {
                            inputText = parseFloat(inputText.replace(",", "."));
                          }
                          if (columnFnKey === "quantity") {
                            inputText = parseFloat(inputText);
                          }
                          values.positions[cell.row.index][
                            columnFnKey as keyof OrderPosition
                          ] = inputText;

                          setFieldValue("positions", [
                            ...(values.positions as OrderPosition[]),
                          ]);

                          setFieldValue("summary", {
                            netAmount: +calculateSumNet(
                              values.positions
                            ).toFixed(2),
                            vat: +(
                              (calculateSumNet(values.positions) / 100) *
                              19
                            ).toFixed(2),
                            grossAmount: +(
                              (calculateSumNet(values.positions) / 100) *
                              119
                            ).toFixed(2),
                          });
                        },
                        variant: "outlined",
                        size: "small",
                      })}
                      enableRowActions={!readonlyMode}
                      initialState={{ density: "compact" }}
                      displayColumnDefOptions={{
                        "mrt-row-actions": {
                          header: "Aktionen",
                          size: 1, //make actions column wider
                        },
                      }}
                      renderTopToolbarCustomActions={() => (
                        <div className="ml-auto mr-5">
                          <div className="text-2xl text-slate-700 font-extrabold flex flex-row justify-between">
                            <span className="mr-4">Gesamt</span>
                            <span className="underline underline-offset-4">
                              {formatNumberToCurrency(
                                values.summary?.grossAmount
                              )}
                            </span>
                          </div>
                          <div className="text-xs text-slate-700 font-extrabold flex flex-row justify-between">
                            <span className="mr-4">Ust 19%</span>
                            <span className="">
                              {formatNumberToCurrency(values.summary?.vat)}
                            </span>
                          </div>
                          <div className="text-xs  text-slate-700 font-extrabold flex flex-row justify-between">
                            <span className="mr-4">Netto</span>
                            <span className="">
                              {formatNumberToCurrency(
                                values.summary?.netAmount
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      renderRowActions={({ row, table, cell }) => (
                        <div className="flex">
                          <IconButton
                            title="Position hinzufügen"
                            disabled={readonlyMode}
                            onClick={() => {
                              let rowIndex = row.index;
                              console.log("rowIndex ", rowIndex);
                              let rows = [
                                ...(values.positions as OrderPosition[]),
                              ];
                              rows.splice(rowIndex + 1, 0, {
                                _id: new ObjectID().toHexString(),
                                position: null,
                                orderProduct: null,
                                priceNet: null,
                                quantity: null,
                              });

                              rows.forEach(
                                (row, index) => (row.position = index + 1)
                              );

                              setFieldValue("positions", rows);
                            }}
                          >
                            <Feather
                              name="plus-circle"
                              size={28}
                              color="#14b8a6"
                            />
                          </IconButton>

                          {row.index !== 0 && (
                            <IconButton
                              title="Position entfernen"
                              disabled={readonlyMode}
                              onClick={() => {
                                console.log(row.index);
                                let rows = [
                                  ...(values.positions as OrderPosition[]),
                                ];
                                rows.splice(row.index, 1);
                                rows.forEach(
                                  (row, index) => (row.position = index + 1)
                                );
                                setFieldValue("positions", rows);
                              }}
                            >
                              <Feather
                                name="minus-circle"
                                size={28}
                                color="#FF4C4C"
                              />
                            </IconButton>
                          )}
                        </div>
                      )}
                      localization={MRT_Localization_DE}
                      positionToolbarAlertBanner="bottom"
                      enableFullScreenToggle
                      enablePagination={true}
                      positionActionsColumn="last"
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          tableLayout: "fixed",
                        },
                      }}
                    />
                  </div>

                 {loadedOrder && (
                   <div className="bg-white sm:col-span-1 shadow-sm rounded-xl p-8">
                   <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                     <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-5 ">
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         strokeWidth="1.5"
                         stroke="#E43D95"
                         className="w-6 h-6"
                       >
                         <path
                           strokeLinecap="round"
                           stroke-linejoin="round"
                           d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                         />
                       </svg>
                     </View>
                     Verlauf
                   </dt>
                   <EntityTimeLine logs={loadedOrder?.logs}></EntityTimeLine>
                 </div>
                 )}
                </dl>
              </div>
            )}
          </>
        </Form>
      )}
    </Formik>
  );
}

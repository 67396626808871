import "react-native-gesture-handler";
import * as React from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Text, View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import {
  CouponCodes,
  GetAllAppUsersQuery,
  OrderDurationType,
  useGetAllAppUsersQuery,
} from "../../../__generated__/graphql-types";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { transformDate } from "../../../helpers";
import { Tooltip } from "@mui/material";

export default function AdminAppUsers() {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const {
    data: users,
    error,
    loading
  } = useGetAllAppUsersQuery();

  const columns = useMemo<MRT_ColumnDef<GetAllAppUsersQuery["getAllAppUsers"][0]>[]>(
    () => [
      {
        accessorFn: (row) => row.username + " (" + row.email + " )",
        header: "Nutzer"
      },
      {
        accessorFn: (row) => row.redeemedCoupons?.length,
        header: "Eingelöste Deals",
        Cell({ cell, column, row, table }) {
          return cell.getValue<number>() > 0 ? (
            <Tooltip title={
              row.original.redeemedCoupons && row.original.redeemedCoupons?.map(coupon => (
                <div>
                  <div>{coupon?.erfuellungsOrt}</div>
                  <div>{coupon?.coupon?.header}</div>
                  <div>{coupon?.coupon?.description}</div>
                  <div>{transformDate(coupon?.redeemedAt)}</div>
                  {row.original.redeemedCoupons!.length > 1 && <div className="my-4">---------------------------------------------------</div>}
                </div>
              ))
            } >
              <div className="border-b border-dashed cursor-pointer max-w-300px">{cell.getValue<number>()}</div>
            </Tooltip>
          ) : (
            <Text
            >
              0
            </Text>
          );
        },
      },
      {
        accessorFn: (row) => transformDate(row.timestamp),
        header: "Registriert am"
      },
      {
        accessorFn: (row) => row.enabled,
        header: "Verifiziert?",
        Cell({ cell, column, row, table }) {
          return cell.getValue() ? (
            <Text
              className={`p-1.5 bg-teal-600 text-white font-semibold rounded-md text-sm uppercase tracking-wide`}
            >
              Ja
            </Text>
          ) : (
            <Text
              className={`p-1.5 bg-red-400 text-white font-semibold rounded-md text-sm uppercase tracking-wide`}
            >
              Nein
            </Text>
          );
        },
      },
      {
        accessorFn: (row) => row.subscription,
        header: "Abonnement ?",
        Cell({ cell, column, row, table }) {
          return cell.getValue() ? (
            <Text
              className={`p-1.5 bg-teal-600 text-white font-semibold rounded-md text-sm uppercase tracking-wide`}
            >
              Ja
            </Text>
          ) : (
            <Text
              className={`p-1.5 bg-yellow-400 text-white font-semibold rounded-md text-sm uppercase tracking-wide`}
            >
              Nein
            </Text>
          );
        },
      },
      {
        accessorFn: (row) => row.subscription,
        header: "Abonnement Typ",

        Cell({ cell, column, row, table }) {
          let subscription = cell.getValue<GetAllAppUsersQuery["getAllAppUsers"][0]["subscription"]>();

          console.log(subscription)
          if (!subscription) {
            return <Text
              className={``}
            >
              -
            </Text>
          }

          return subscription?.isStripeSubscription ? (
            <Tooltip title={`Zeitraum: ${transformDate(subscription?.stripeStartDate)} - ${transformDate(subscription?.stripeEndDate)}`} >
              <div className="p-1.5 bg-purple-600 text-white font-semibold rounded-md text-sm uppercase tracking-wide">Stripe {subscription.stripeStatus}</div>
            </Tooltip>
          ) : (
            <Tooltip title={`-> Navigiere zu Bestellung #${subscription?.employeeCoupon?.order?.orderNumber}\nZeitraum: ${transformDate(subscription?.employeeCoupon?.startDate)} - ${transformDate(subscription?.employeeCoupon?.endDate)}`} >
              <div onClick={() => navigate(`/client/orders/${subscription?.employeeCoupon?.order?._id}`)} className="cursor-pointer p-1.5 bg-cyan-800 text-white font-semibold rounded-md text-sm uppercase tracking-wide">Mitarbeiter-Benefit ({subscription?.employeeCoupon?.orderPosition?.orderProduct?.duration} {subscription?.employeeCoupon?.orderPosition?.orderProduct?.durationType === OrderDurationType.Days ? "Tage" : "Monate"})</div>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <View className="px-3 pb-3 mx-3 mb-3 rounded-xl bg-white">
      <MaterialReactTable
        enableColumnFilterModes
        enableRowActions
        localization={MRT_Localization_DE}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle
        data={users?.getAllAppUsers || []}
        initialState={{ density: "compact", pagination: { pageIndex: 0, pageSize: 100 } }}
        state={{
          showSkeletons: loading,
          showAlertBanner: !!error,
        }}
        columns={columns as any}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            App Nutzer
          </h2>
        )}
      ></MaterialReactTable>
    </View>
  );
}

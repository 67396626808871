import React, { ReactNode, useEffect, useState } from "react";
import { Text, View } from "react-native";
import { classNames } from "../../../helpers";
import { AsyncFunction, SyncFunction } from "../../../constants";
import LoadingCircleSVG from "../admin/svg-icons/loading-circle-svg";

function ClientButton(props: {
  onClick?: AsyncFunction | SyncFunction;
  icon?: ReactNode;
  label: string;
  type?: "submit" | "button";
  disabled?: boolean;
  className?: string;
  iconLeft?: boolean;
  loading?: boolean;
  mode: "primary" | "secondary";
}) {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(props.disabled || false);
  }, [props.disabled]);

  const buttonStyles: { [key: string]: { button: string; text: string } } = {
    ["primary"]: {
      button: disabled ? "bg-gray-100 cursor-not-allowed" : "bg-pink-500 hover:opacity-80",
      text: disabled ? " text-gray-500" : "text-white",
    },
    ["secondary"]: {
      button: disabled ? "bg-gray-50 cursor-not-allowed" : "bg-gray-50  hover:opacity-80",
      text: disabled ? "text-gray-500" : "text-pink-500",
    },
  };
  const [loading, setLoading] = useState(props.loading);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  return (
    <button
      onClick={async () => {
        if (!disabled && !loading) {
          setLoading(true);
          setDisabled(true);
          try {
            props.onClick && await props.onClick();
          } catch (error) {
            throw error;
          } finally {
            setLoading(false);
            setDisabled(false);
          }
        }
      }}
      type={props.type}
      className={classNames(
        buttonStyles[props.mode].button,
        props.className,
        `ml-1 inline-flex items-center uppercase px-3 py-3 border border-transparent shadow-lg text-sm leading-4 font-bold rounded-xl focus:outline-none tracking-wider`
      )}
      disabled={disabled}
    >
      <View className="flex flex-row items-center">
        <View className={buttonStyles[props.mode].text}>
          {props.iconLeft && !loading && props.icon}
        </View>
        <Text
          className={classNames(
            buttonStyles[props.mode].text,
            "font-bold text-xs",
            props.iconLeft || props.icon
              ? props.iconLeft
                ? "ml-1"
                : "mr-1"
              : ""
          )}
        >
          {loading && <LoadingCircleSVG />}
          {props.label}
        </Text>
        <View className={buttonStyles[props.mode].text}>
          {!props.iconLeft && !loading && props.icon}
        </View>
      </View>
    </button>
  );
}

ClientButton.defaultProps = {
  type: "button",
  color: "pink-500",
  mode: "primary",
};

export default ClientButton;

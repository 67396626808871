import { Feather, Octicons } from "@expo/vector-icons";
import { Formik, Form } from "formik";
import { useState } from "react";
import { View } from "react-native";
import { useToasts } from "react-toast-notifications";
import {
  Company,
  PerformancePlace,
  useUpdatePerformancePlacesMutation,
} from "../../../../../__generated__/graphql-types";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import ClientButton from "../../../web-components/ClientButton";
import { contuineFormButton, previousFormButton } from "../screens/ContractFormScreen";
import * as yup from "yup";
import HouseSVG from "../../svg-icons/house-svg";
import AddMapMarkerSVG from "../../svg-icons/add-map-marker.svg";
import NiceModal from "@ebay/nice-modal-react";
import { AddPerformancePlaceModal } from "../../../web-modals/AddPerformancePlace.modal";
import GoogleMapsSVG from "../../svg-icons/google-maps.svg";
import { MySwal } from "../../../../../../WebApp";
import ObjectID from "bson-objectid";

export default function ErfuellungsortForm(props: {
  form: Company;
  contuineAndSave: () => void;
  contuineWithoutSave: () => void;
  onBackClick: () => void;
}) {
  let schema = yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        _id: yup.string(),
        erfuellungsOrt: yup.string().required(),
        strasse: yup.string().required(),
        postleitzahl: yup.string().required(),
        ort: yup.string().required(),
        land: yup.string().default("Deutschland").required(),
        location: yup
          .object()
          .shape({
            coordinates: yup.array().of(yup.string()),
          })
          .required(),
        telefon: yup.string(),
        mobil: yup.string(),
        email: yup.string(),
        regions: yup.array().of(yup.object()).min(1),
        website: yup.string(),
        speisekarte: yup.string(),
        instagram: yup.string(),
        facebook: yup.string(),
        tiktok: yup.string(),
      })
    );

  const [formData] = useState<Company>(props.form);
  console.log("#2 erfüllungsort ", formData)
  const [updatePerformancePlaces] = useUpdatePerformancePlacesMutation();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Formik
      initialValues={props.form.performancePlaces || []}
      validationSchema={schema}
      validateOnMount
      onSubmit={async (performancePlaces) => {
        console.log("OLD FORM -> ", formData);
        console.log("newErfuellungsOrtForm -> ", performancePlaces);
        setIsLoading(true);
        try {
          let response = await updatePerformancePlaces({ variables: { companyId: props.form._id, updatePerformancePlacesInputs: performancePlaces } });
          if (response.data?.updatePerformancePlaces) {
            props.contuineAndSave();
          }
        } catch (error) {
          addToast("FEHLER: Erfüllungsorte konnten nicht gespeichert werden!" + error, { appearance: "error" });
          console.log("Fehler ", error);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {({ isValid, values, errors, setValues, submitForm }) => (
        <Form>
          {/* {JSON.stringify(values, null, "\t")}
          {JSON.stringify(errors, null, "\t")} */}
          <View className="flex flex-row ml-auto mb-5 justify-end">
            {previousFormButton(props.onBackClick)}

            {contuineFormButton({
              formIsReadonly: false,
              disabled: !isValid || !values?.length,
              isLoading,
              submitForm,
              contuineWithoutSaving: props.contuineWithoutSave
            })}
          </View>

          <AdminFormHeader>Erfüllungsorte</AdminFormHeader>
          <div className="grid grid-cols-4 gap-2 m-3">
            <button
              hidden={props.form.isReadonly}
              onClick={async () => {
                let modalResult = (await NiceModal.show(
                  AddPerformancePlaceModal,
                  {
                    company: formData,
                    performancePlaces: values,
                    ortIndex: -1,
                  }
                )) as {
                  resolved: boolean;
                  erfuellungsort: PerformancePlace;
                };
                if (!modalResult.resolved) {
                  return;
                }
                console.log("modalResult ", modalResult);
                let newOrte = [modalResult.erfuellungsort, ...(values || [])];
                setValues(newOrte);
              }}
              type="button"
              className="border-2 border-gray-200 border-dashed rounded-2xl p-6 text-center hover:border-gray-300 hover:shadow-md  focus:outline-none "
            >
              <div className="mx-auto flex flex-col justify-center items-center">
                <br></br>
                <AddMapMarkerSVG></AddMapMarkerSVG>
                <div className="mx-auto flex flex-col justify-center items-center">
                  <span className="mt-2 block text-base font-bold text-gray-900">
                    Erfüllungsort hinzufügen
                  </span>
                  <br></br>
                </div>
              </div>
            </button>

            {values.map((erfuellungsOrt, ortIndex) => (
              <div className="p-3 text-center focus:outline-none rounded-2xl bg-gray-50">
                <div className="mx-auto flex flex-col justify-center">
                  <div className="flex flex-row justify-between flex-wrap">
                    <div
                      className="bg-white p-2 rounded-md hover:shadow-md cursor-pointer"
                      onClick={async () => {
                        if (erfuellungsOrt.location?.coordinates) {
                          window.open(
                            `https://maps.google.com/?q=${erfuellungsOrt.location.coordinates[1]},${erfuellungsOrt.location.coordinates[0]}`
                          );
                        }
                      }}
                    >
                      <GoogleMapsSVG></GoogleMapsSVG>
                    </div>

                    <div className="flex flex-row ml-auto flex-wrap">
                      {props.form.isReadonly && (
                        <div
                          className="flex items-center bg-white p-2 rounded-md hover:shadow-md cursor-pointer"
                          title="Erfüllungsort ansehen"
                          onClick={async () => {
                            try {
                              let modalResult = (await NiceModal.show(
                                AddPerformancePlaceModal,
                                {
                                  company: formData,
                                  performancePlaces: values,
                                  ortIndex: ortIndex,
                                  isReadonly: true
                                }
                              )) as {
                                resolved: boolean;
                                erfuellungsort: PerformancePlace;
                              };
                              if (!modalResult.resolved) {
                                return;
                              }
                              let newOrte = [...values];
                              newOrte[ortIndex] = modalResult.erfuellungsort;
                              setValues(newOrte);
                            } catch (e) {
                              console.error(e);
                            }
                          }}
                        >
                          <Feather name="eye" size={24} color="#1C274C" />
                        </div>
                      )}

                      {!props.form.isReadonly && (
                        <div className="flex flex-row">
                          <div
                            className="flex items-center bg-white p-2 rounded-md hover:shadow-md ml-2 cursor-pointer"
                            title="Erfüllungsort kopieren"
                            onClick={async () => {
                              try {
                                let source = values[ortIndex];

                                let copy = Object.assign({}, source);
                                copy.strasse = "";
                                copy.erfuellungsOrt = "";
                                copy.location = {};
                                copy.postleitzahl = "";
                                copy.ort = "";
                                copy.telefon = "";
                                copy.mobil = "";
                                copy.whatsapp = "";
                                copy.email = "";
                                copy.entwertungs_email = "";
                                copy.regions = [];
                                copy._id = new ObjectID().toHexString();

                                let newOrte = [copy, ...(values || [])];
                                console.log("newOrte ", newOrte);
                                // formData.performancePlaces = newOrte;
                                setValues(newOrte);
                              } catch (e) {
                                console.error(e);
                              }
                            }}
                          >
                            <Feather name="copy" size={24} color="#1C274C" />
                          </div>

                          <div
                            className="flex items-center bg-white p-2 rounded-md hover:shadow-md ml-2 cursor-pointer"
                            title="Erfüllungsort bearbeiten"
                            onClick={async () => {
                              let modalResult = (await NiceModal.show(
                                AddPerformancePlaceModal,
                                {
                                  company: formData,
                                  performancePlaces: values,
                                  ortIndex: ortIndex,
                                }
                              )) as {
                                resolved: boolean;
                                erfuellungsort: PerformancePlace;
                              };

                              try {
                                if (!modalResult.resolved) {
                                  return;
                                }
                                let newOrte = [...values];
                                newOrte[ortIndex] = modalResult.erfuellungsort;
                                console.log("newOrte ", newOrte);
                                setValues(newOrte);
                              } catch (e) {
                                console.error(e);
                              }
                            }}
                          >
                            <Octicons name="pencil" size={24} color="#1C274C" />
                          </div>

                          <div
                            className="flex items-center bg-white p-2 rounded-md ml-2 hover:shadow-md cursor-pointer"
                            title="Erfüllungsort entfernen"
                            onClick={() => {
                              MySwal.fire({
                                title: `Erfüllungsort ${erfuellungsOrt.erfuellungsOrt} entfernen? \n`,
                                showDenyButton: true,
                                confirmButtonText: `Ja, Erfüllungsort entfernen!`,
                                denyButtonText: `Abbrechen`,
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  let orte = [...values];
                                  orte.splice(ortIndex, 1);
                                  setValues(orte);
                                }
                              });
                            }}
                          >
                            <Octicons name="trash" size={24} color="#FF4C4C" />
                          </div>

                        </div>
                      )}
                    </div>

                  </div>
                  <div className="mx-auto">
                    <HouseSVG></HouseSVG>
                  </div>

                  <span className="mt-2 block text-base font-bold text-gray-900">
                    {erfuellungsOrt.erfuellungsOrt}
                  </span>
                  <span className="mt-2 block text-sm text-gray-400">
                    {erfuellungsOrt.strasse}
                    <br></br>
                    {erfuellungsOrt.postleitzahl + " " + erfuellungsOrt.ort}
                    <br></br>
                    {erfuellungsOrt.land}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </Form>
      )}
    </Formik>
  );
}

import React, { useEffect, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import ClientTextInput from "../web-components/ClientTextInput";
import ClientInputWrapper from "../web-components/ClientInputWrapper";
import {
  Company,
  PerformancePlace,
  Region,
  useGetCoordinatesFromAddressQuery,
  useGetRegionsQuery,
} from "../../../__generated__/graphql-types";
import { Text } from "react-native";
import AdminFormHeader from "../web-components/AdminFormHeader";
import { MySwal } from "../../../../WebApp";
import ObjectID from "bson-objectid";
import { COUNTRIES } from "../web-components/dropdown_data";
import { classNames } from "../../../helpers";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const AddPerformancePlaceModal = NiceModal.create(
  (props: {
    company: Company;
    performancePlaces: PerformancePlace[];
    ortIndex: number,
    isReadonly: boolean
  }) => {

    const modal = useModal();
    let schema = yup.object({
      _id: yup.string().required(),
      company: yup.object(),
      erfuellungsOrt: yup.string().required(),
      strasse: yup.string().required(),
      ort: yup.string().required(),
      postleitzahl: yup.string().required(),
      land: yup.string().required(),
      location: yup
        .object()
        .shape({
          type: yup.string(), // Point
          coordinates: yup.array().of(yup.string()),
        })
        .required(),
      telefon: yup.string(),
      mobil: yup.string(),
      whatsapp: yup.string(),
      email: yup.string(),
      entwertungs_email: yup.string().required(),
      website: yup.string(),
      regions: yup.array().of(yup.object()
        .shape({
          _id: yup.string(),
          region: yup.string()
        })).min(1),
      speisekarte: yup.string(),
      instagram: yup.string(),
      facebook: yup.string(),
      tiktok: yup.string(),
    });

    const { refetch: getCoordinatesFromAddressQuery } = useGetCoordinatesFromAddressQuery({});
    const { data: regions, loading: regionsLoading, error } = useGetRegionsQuery();
    const [googleMapsStaticImg, setGoogleMapsStaticImg] = useState("");
    const [addressSubmitted, setAddressSubmitted] = useState(false);
    const [addressNotFound, setAddressNotFound] = useState(false);
    const [currentLatLng, setCurrentLatLng] = useState<{ lat: number; lng: number } | undefined>(undefined);
    const [erfuellungsOrt, setErfuellungsort] = useState<PerformancePlace | undefined>(undefined);

    useEffect(() => {
      if (props.ortIndex > -1) {
        setErfuellungsort(props.performancePlaces[props.ortIndex]);
      }
      else {
        setErfuellungsort({
          _id: new ObjectID().toHexString(),
          email: "",
          company: props.company,
          entwertungs_email: "",
          erfuellungsOrt: "",
          facebook: "",
          instagram: "",
          land: "",
          location: { type: "Point", coordinates: [] },
          mobil: "",
          openingHours: {
            closedDays: [],
            days: [],
            extraOpeningHours: "",
          },
          ort: "",
          postleitzahl: "",
          speisekarte: "",
          strasse: "",
          telefon: "",
          tiktok: "",
          website: "",
          whatsapp: "",
          imagesDetails: { images: [] },
          propertyFeatures: {},
        } as PerformancePlace);
      }
    }, [props.ortIndex, props.company, props.performancePlaces]);

    console.log(erfuellungsOrt);

    if (!erfuellungsOrt) {
      return;
    }

    const validateAdress = async (
      isValid: boolean,
      values?: PerformancePlace
    ): Promise<PerformancePlace["location"] | undefined> => {
      if (!values) {
        return undefined;
      }

      if (isValid) {
        let coordinatesResult = await getCoordinatesFromAddressQuery({
          address:
            values.erfuellungsOrt +
            " " +
            values.strasse +
            " " +
            values.postleitzahl +
            " " +
            values.ort +
            " " +
            values.land,
        });

        let coordinates =
          coordinatesResult?.data?.getCoordinatesFromAddress?.coordinates;
        if (!coordinates?.length) {
          setCurrentLatLng(undefined);
          setAddressNotFound(true);
          await showAdressNotFoundSwal(values);
          setAddressSubmitted(false);
          return undefined;
        }

        if (
          props.performancePlaces?.filter(
            (ort) =>
              ort.location &&
              ort.location.coordinates &&
              coordinates &&
              ort._id !== values._id && // Damit bearbeiten möglich ist auf dem selben Erfüllungsort
              ort.location.coordinates[0] === coordinates[0] &&
              ort.location.coordinates[1] === coordinates[1]
          )?.length > 0
        ) {
          await MySwal.fire({
            customClass: "swal-container",
            title: `Duplikate Adresse`,
            html: `
            <div>
              ${values.erfuellungsOrt}<br>
              ${values.postleitzahl}${", "}${values.ort}<br>
              ${values.land}<br><br>
              <strong> ist bereits ein Erfüllungsort! </strong> 
            </div>`,
            showDenyButton: false,
            confirmButtonText: "Verstanden",
          });
          return;
        }

        try {
          let lat = coordinates[0];
          let lng = coordinates[1];
          setCurrentLatLng({ lat, lng });
          setAddressSubmitted(true);
          setAddressNotFound(false);
          setGoogleMapsStaticImg(
            `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=525x525&markers=color:red%7C%7C${lat},${lng}&key=AIzaSyC6l_ILeaj4KFatNwcKerTcixOYmnrg1ok`
          );
          return { coordinates: [lng, lat] };
        } catch (error) {
          console.error(error);
          setAddressSubmitted(false);
          await showAdressNotFoundSwal(values);
          setAddressNotFound(true);
        }
      } else {
        setGoogleMapsStaticImg(``);
      }
    };
    return (
      <Formik
        initialValues={erfuellungsOrt || {}}
        validationSchema={schema}
        validateOnMount
        onSubmit={(erfuellungsort) => {
          console.log("NEW FORM -> ", erfuellungsort);
        }}
      >
        {({ isValid, values, errors, setFieldValue, dirty }) => (
          <Dialog
            TransitionComponent={Transition}
            fullWidth
            maxWidth={"xl"}
            open={modal.visible}
            onClose={() => {
              modal.resolve({ resolved: false });
              modal.hide();
            }}
            TransitionProps={{
              onExited: () => {
                modal.resolve({ resolved: false });
                modal.remove();
              },
            }}
          >
            <DialogTitle>
              <div className="flex flex-row justify-between">
                <div>
                  {props.ortIndex > -1 ? `Erfüllungsort - ${erfuellungsOrt?.erfuellungsOrt}` : "Erfüllungsort hinzufügen"}
                  <div className="text-xs text-gray-300 font-thin">
                    ID: {erfuellungsOrt?._id}
                  </div>
                </div>
                <div className="cursor-pointer" onClick={() => {
                  modal.resolve({ resolved: false });
                  modal.hide();
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              {/* {JSON.stringify(values, null, "\t")}
              {JSON.stringify(errors, null, "\t")} */}
              <Form onChange={() => setAddressNotFound(false)}>
                <AdminFormHeader noTopMargin>Standort</AdminFormHeader>
                <Field
                  component={ClientTextInput}
                  name="erfuellungsOrt"
                  label="Erfüllungsort"
                  mode="text"
                  disabled={addressSubmitted || props.isReadonly}
                ></Field>

                <ClientInputWrapper>
                  <Field
                    component={ClientTextInput}
                    name="strasse"
                    grow
                    label="Straße"
                    disabled={addressSubmitted || props.isReadonly}
                    mode="textAndDropdown"
                    onClick={(value: string) => setFieldValue("strasse", value)}
                    dropdownData={[props.company.betreiberDetails?.strasse, props.company.kommunikationsanschriftDetails?.strasse]}
                    addPleaseSelectOption={false}
                  ></Field>
                  <Field
                    component={ClientTextInput}
                    name="postleitzahl"
                    disabled={addressSubmitted || props.isReadonly}
                    label="Plz"
                    mode="textAndDropdown"
                    onClick={(value: string) => setFieldValue("postleitzahl", value)}
                    dropdownData={[props.company.betreiberDetails?.postleitzahl, props.company.kommunikationsanschriftDetails?.postleitzahl]}
                    addPleaseSelectOption={false}
                  ></Field>
                  <Field
                    component={ClientTextInput}
                    name="ort"
                    label="Ort"
                    grow
                    disabled={addressSubmitted || props.isReadonly}
                    mode="textAndDropdown"
                    onClick={(value: string) => {
                      console.log('ORT ORT ORT ORT ORT ORT ORT', value)
                      setFieldValue("ort", value)
                    }}
                    dropdownData={[props.company.betreiberDetails?.ort, props.company.kommunikationsanschriftDetails?.ort]}
                    addPleaseSelectOption={false}
                  ></Field>
                  <Field
                    component={ClientTextInput}
                    name="land"
                    label="Land"
                    disabled={addressSubmitted || props.isReadonly}
                    mode="dropdown"
                    onClick={(value: string) => {
                      setFieldValue("land", value)
                    }}
                    dropdownData={[props.company.betreiberDetails?.land, ...COUNTRIES]}
                    addPleaseSelectOption={false}
                  ></Field>
                </ClientInputWrapper>
                <div>
                  <div className="ml-3 mt-6">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                      Regionen
                    </label>
                    <Autocomplete
                      options={(
                        values.regions && values.regions?.length
                          ? regions?.getRegions?.length === values?.regions?.length ? [] : regions?.getRegions.filter(region => values.regions?.find(r => r?._id !== region?._id))
                          : regions?.getRegions
                      ) || []}
                      size="small"
                      multiple
                      loading={regionsLoading}
                      clearOnBlur={false}
                      value={values.regions}
                      loadingText="Regionen werden geladen..."
                      noOptionsText={`Keine Region gefunden.`}
                      disablePortal={false}
                      onChange={(event, selectedRegions) => {
                        if (!selectedRegions) {
                          return;
                        }
                        setFieldValue(
                          'regions',
                          selectedRegions.map(a => {
                            return { _id: a._id, region: a.region }
                          })
                        );

                      }}
                      getOptionLabel={(textTemplate: Region) =>
                        `${textTemplate.region}`
                      }
                      renderOption={(props, option, { selected, index }) => (
                        <div
                          {...props}
                          className={classNames("p-3 hover:bg-gray-50 cursor-pointer", "border border-b border-gray-50")}
                        >
                          <div className="text-sm text-slate-700 font-bold">
                            {option.region}
                          </div>
                          <div className="text-xs text-slate-500">
                            {option.description}
                          </div>
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Region(en) auswählen`}
                        ></TextField>
                      )}
                    />
                  </div>
                </div>
                <AdminFormHeader>Kontakt</AdminFormHeader>
                <ClientInputWrapper>
                  <Field
                    component={ClientTextInput}
                    name="telefon"
                    label="Telefon"
                    disabled={addressSubmitted}
                    mode="textAndDropdown"
                    dropdownData={[props.company.betreiberDetails?.telefon, props.company.kommunikationsanschriftDetails?.telefon]}
                    addPleaseSelectOption={false}
                    onClick={(value: string) => setFieldValue("telefon", value)}
                  ></Field>

                  <Field
                    component={ClientTextInput}
                    name="mobil"
                    label="Mobil"
                    mode="textAndDropdown"
                    disabled={addressSubmitted}
                    dropdownData={[props.company.betreiberDetails?.mobil, props.company.kommunikationsanschriftDetails?.mobil]}
                    addPleaseSelectOption={false}
                    onClick={(value: string) => setFieldValue("mobil", value)}
                  ></Field>

                  <Field
                    component={ClientTextInput}
                    name="whatsapp"
                    label="WhatsApp"
                    mode="textAndDropdown"
                    disabled={addressSubmitted}
                    dropdownData={[props.company.betreiberDetails?.whatsapp]}
                    addPleaseSelectOption={false}
                    onClick={(value: string) =>
                      setFieldValue("whatsapp", value)
                    }
                  ></Field>

                  <Field
                    component={ClientTextInput}
                    name="email"
                    label="E-Mail"
                    viewClassName={"w-1/2 ml-3 mt-5"}
                    mode="textAndDropdown"
                    disabled={addressSubmitted}
                    dropdownData={[props.company.betreiberDetails?.email, props.company.kommunikationsanschriftDetails?.email]}
                    addPleaseSelectOption={false}
                    onClick={(value: string) => setFieldValue("email", value)}
                  ></Field>
                </ClientInputWrapper>

                <AdminFormHeader>Entwertungs E-Mail</AdminFormHeader>
                <ClientInputWrapper>
                  <Field
                    component={ClientTextInput}
                    viewClassName={"w-1/2 ml-3 mt-5"}
                    name="entwertungs_email"
                    label="Entwertungs E-Mail"
                    mode="textAndDropdown"
                    disabled={addressSubmitted}
                    dropdownData={[props.company.betreiberDetails?.email, props.company.kommunikationsanschriftDetails?.email]}
                    addPleaseSelectOption={false}
                    onClick={(value: string) =>
                      setFieldValue("entwertungs_email", value)
                    }
                  ></Field>
                </ClientInputWrapper>
                <AdminFormHeader>
                  Links: Social Media, Website, Preisliste/Speisekarte, E-Mail
                </AdminFormHeader>
                <Field
                  component={ClientTextInput}
                  name="website"
                  label="Website"
                  disabled={addressSubmitted}
                  mode="text"
                ></Field>
                <Field
                  component={ClientTextInput}
                  name="speisekarte"
                  label="Preisliste / Speisekarte"
                  disabled={addressSubmitted}
                  mode="text"
                ></Field>
                <Field
                  component={ClientTextInput}
                  name="instagram"
                  label="Instagram"
                  disabled={addressSubmitted}
                  mode="text"
                ></Field>
                <Field
                  component={ClientTextInput}
                  name="facebook"
                  label="Facebook"
                  disabled={addressSubmitted}
                  mode="text"
                ></Field>
                <Field
                  component={ClientTextInput}
                  name="tiktok"
                  label="TikTok"
                  disabled={addressSubmitted}
                  mode="text"
                ></Field>
              </Form>
              {!addressNotFound && (
                <img
                  className="mx-auto ml-3 mt-5 m-3 rounded-xl cursor-pointer"
                  src={googleMapsStaticImg}
                  onClick={() => {
                    window.open(
                      `https://maps.google.com/?q=${currentLatLng?.lat},${currentLatLng?.lng}`
                    );
                  }}
                ></img>
              )}
              {currentLatLng && addressSubmitted && (
                <Text className="text-xs text-slate-600 ml-3">
                  Position: {currentLatLng?.lat + " " + currentLatLng?.lng}
                </Text>
              )}
            </DialogContent>

            {props.isReadonly && (
              <DialogActions>
                <Button
                  onClick={async () => {
                    if (!isValid) {
                      return;
                    }
                    modal.resolve({ resolved: true, erfuellungsort: values });
                    modal.hide();
                  }}
                  disabled={!isValid || !dirty}
                  color="info"
                  variant="outlined"
                >
                  Änderungen speichern
                </Button>
              </DialogActions>
            )}

            {!props.isReadonly && (
              <DialogActions>
                {!addressSubmitted && (
                  <Button
                    onClick={async () => {
                      if (!isValid) {
                        return;
                      }
                      let result = await validateAdress(isValid, values);
                      setFieldValue("location", {
                        type: "Point",
                        coordinates: result?.coordinates,
                      });
                    }}
                    disabled={!isValid}
                    variant="outlined"
                    color="primary"
                  >
                    Adresse prüfen
                  </Button>
                )}
                {addressSubmitted && (
                  <Button
                    onClick={async () => {
                      setAddressSubmitted(false);
                      setGoogleMapsStaticImg("");
                    }}
                    disabled={!isValid}
                    color="info"
                    variant="outlined"
                  >
                    bearbeiten
                  </Button>
                )}
                {addressSubmitted && (
                  <Button
                    onClick={async () => {
                      if (!isValid || !addressSubmitted) {
                        return;
                      }

                      modal.resolve({ resolved: true, erfuellungsort: values });
                      modal.hide();
                    }}
                    disabled={!isValid}
                    color="success"
                    variant="outlined"
                  >
                    Erfüllungsort hinzufügen
                  </Button>
                )}
              </DialogActions>
            )}
          </Dialog>
        )}
      </Formik>
    );
  }
);
async function showAdressNotFoundSwal(values: PerformancePlace) {
  await MySwal.fire({
    customClass: "swal-container",
    title: `Adresse nicht gefunden`,
    text: `${values.erfuellungsOrt +
      " " +
      values.strasse +
      " " +
      values.postleitzahl +
      " " +
      values.ort +
      " " +
      values.land
      }
            \nwurde nicht in Google Maps gefunden!`,
    showDenyButton: false,
    confirmButtonText: "OK",
  });
}

import { useState } from "react";
import { TouchableHighlight, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { useNavigate } from "react-router-dom";
import { useGetAllCouponTextTemplatesQuery, useGetRegionQuery, useGetRegionsQuery } from "../../../../__generated__/graphql-types";
import { transformDate } from "../../../../helpers";

export default function RegionList() {
  const navigate = useNavigate();
  const { data: regions, loading, error } = useGetRegionsQuery();
  console.log(regions);

  if (loading) return <span>Wird geladen...</span>;
  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <View className="ml-4">
      <TouchableHighlight
        onPress={() => navigate("create")}
        className="max-h-32 bg-white p-5 rounded-xl cursor-pointer shadow-sm hover:shadow-lg my-2 w-60 text-center flex items-center"
      >
        <AntDesign name="plus" size={50} color="#14b8a6" />
      </TouchableHighlight>

      <div className="grid grid-cols-2 gap-2">
        {regions &&
          regions?.getRegions?.map((region) => (
            <div
              className="bg-white px-4 py-5 sm:px-6 shadow-sm cursor-pointer hover:shadow-lg rounded-xl"
              onClick={() => navigate(region._id)}
            >
              <div>
                <p className="text-xs text-gray-400 ml-auto text-right">
                  Zuletzt aktualisiert: {transformDate(region.updatedAt)}
                </p>
                <div className="flex space-x-3">
                  <div className="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ec4899" className="h-20 w-20 ">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525" />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-bold text-gray-900">
                      <p className="text-pink-500 text-base">
                        {region.region}
                      </p>
                      <p className="">
                        {region.description}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

    </View>
  );
}

import { FontAwesome5 } from "@expo/vector-icons";
import { Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-dom";

export default function AdminSettingsScreen() {
  const navigate = useNavigate();
  return (
    <View className="flex flex-wrap gap-2 m-10">
      <TouchableOpacity onPress={() => navigate("text-templates")} className="flex flex-row  w-60 rounded-lg p-4 border border-gray-100 bg-white cursor-pointer hover:border-gray-200 items-center">
        <View className="bg-gray-100 w-12 p-3 rounded-md text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ec4899" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>

        </View>
        <View>
          <Text className="text-pink-500 font-semibold ml-3">
            Textvorlagen
          </Text>
          <Text className="text-gray-400 ml-3 text-xs font-light">
            Anpassen der Vorlagen
          </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => navigate("categories")} className="flex flex-row  w-60 rounded-lg p-4 border border-gray-100 bg-white cursor-pointer hover:border-gray-200 items-center">
        <View className="bg-gray-100 w-12 p-3 rounded-md text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ec4899" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
          </svg>
        </View>
        <View>
          <Text className="text-pink-500 font-semibold ml-3">
            Kategorien
          </Text>
          <Text className="text-gray-400 ml-3 text-xs font-light">
            Anpassen der Kategorien
          </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => navigate("regions")} className="flex flex-row  w-60 rounded-lg p-4 border border-gray-100 bg-white cursor-pointer hover:border-gray-200 items-center">
        <View className="bg-gray-100 w-12 p-3 rounded-md text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ec4899" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525" />
          </svg>

        </View>
        <View>
          <Text className="text-pink-500 font-semibold ml-3">
            Regionen
          </Text>
          <Text className="text-gray-400 ml-3 text-xs font-light">
            Anpassen der Regionen
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

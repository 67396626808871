import { View, Text, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import { MaterialCommunityIcons, EvilIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { selectAppUser } from '../../store/native-slices/userSlice.native';
import { CompanyListItem, useAddOrRemoveFavoriteMutation } from '../../__generated__/graphql-types';
import { classNames } from '../../helpers';
import { Image } from 'expo-image';
import { ScrollView } from 'react-native-gesture-handler';
import { CompaniesFilterOptions } from '../app/services/FilterHelpers';
import { NavigationProp } from '@react-navigation/native';

export default function CompaniesListItem({
    performancePlace,
    navigation,
    showCloseIcon,
    onCloseIconPress,
    isFavorite,
    itemKey
}: {
    performancePlace: CompanyListItem,
    navigation: NavigationProp<any, any>,
    showCloseIcon?: boolean,
    onCloseIconPress?: () => void,
    isFavorite: boolean,
    itemKey: string
}) {
    const { currentAppUser: user, isLoggedIn } = useSelector(selectAppUser);
    const [addOrRemoveFavorite] = useAddOrRemoveFavoriteMutation();
    const [favorite, setIsFavorite] = useState(isFavorite);

    return (
        <View
            className="bg-white border border-gray-50 rounded-3xl"
            key={itemKey}
        >
            <TouchableOpacity
                className="rounded-3xl"
                onPress={() => {
                    navigation?.navigate("company-detail-modal", {
                        performancePlaceId: performancePlace._id,
                        isFavorite: favorite
                    });
                }}>
                <View className='flex flex-row right-0 absolute z-40'>
                    <TouchableOpacity activeOpacity={0.6} className={classNames(showCloseIcon ? "mr-3" : "mr-2", "bg-white border border-gray-100 mt-2 rounded-3xl w-12 h-12 justify-center text-center text-white")}
                        onPress={async () => {
                            if (!isLoggedIn) {
                                navigation.navigate("register-screen")
                            } else {
                                await addOrRemoveFavorite({
                                    variables: {
                                        performancePlaceId: performancePlace._id!
                                    }
                                });
                                setIsFavorite(a => !a);
                            }
                        }}>
                        <View className="mx-auto">
                            {favorite ?
                                <MaterialCommunityIcons name="cards-heart" size={28} color="#ec4899" />
                                :
                                <MaterialCommunityIcons name="cards-heart-outline" size={28} color={"#ec4899"} />
                            }
                        </View>
                    </TouchableOpacity>

                    {showCloseIcon && (
                        <TouchableOpacity activeOpacity={0.6} className="rounded-3xl bg-white right-0 mt-2 mr-3 w-12 h-12 justify-center border border-gray-100 text-center text-white"
                            onPress={async () => {
                                onCloseIconPress && onCloseIconPress();
                            }}>
                            <View className="mx-auto">
                                <MaterialCommunityIcons name="close" size={26} color="#ec4899" />
                            </View>
                        </TouchableOpacity>
                    )}

                </View>


                {performancePlace.image && (
                    <View style={{ borderRadius: 30 }}>
                        <Image
                            style={{
                                objectFit: "cover",
                                zIndex: 10,
                                width: "auto",
                                height: 170,
                                borderRadius: 20
                            }}
                            source={performancePlace.image}
                        ></Image>
                        {/* <Text>BILD CLOUDAINARY</Text> */}
                    </View>
                )}

                {!performancePlace.image && (
                    <View className="items-center justify-center w-full h-40 object-cover rounded-3xl m-auto">
                        <EvilIcons name="image" size={140} color="#E5E7EB" />
                    </View>
                )}

                {performancePlace.logo && (
                    <View className="absolute z-40 p-2 border-0 bg-white mt-1" style={{ borderRadius: 16, marginTop: 95, left: "40%" }}>
                        <Image
                            contentFit="contain"
                            style={{ objectFit: "contain", borderRadius: 0, width: 70, height: 55 }}
                            source={performancePlace.logo}
                        ></Image>
                        {/* <Text>LOGO CLOUDAINARY</Text> */}
                    </View>
                )}


                <Text className="text-xl text-gray-700 font-semibold leading-6 my-2 ml-2 z-50">
                    {performancePlace.erfuellungsOrt}
                </Text>
            </TouchableOpacity>
            <ScrollView horizontal showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} className="ml-4 mb-4">
                <View className="flex flex-row">
                    <View className="mt-0.5" style={{ flexDirection: "row", width: "100%" }}>
                        {performancePlace.totalSaving && performancePlace.totalSaving > 0 && (
                            <View
                                style={{
                                    borderRadius: 30
                                }}
                                className="bg-slate-100"
                            >
                                <View className='flex flex-row items-center justify-center'>
                                    <View className='ml-4'>
                                        <MaterialCommunityIcons name="tilde" size={9} color="rgb(15, 23, 42)" />
                                    </View>
                                    <Text className='mx-auto items-center py-1 pl-0.5 pr-4 font-normal  text-center  text-slate-900 text-sm'>{Math.round(performancePlace.totalSaving) + "€ Vorteil"}</Text>
                                </View>
                            </View>
                        )}
                        <View
                            style={{
                                borderRadius: 30
                            }}
                            className="bg-slate-100 rounded-xl ml-1.5"
                        >
                            <Text className="mx-auto items-center py-1 px-4 font-normal  text-center  text-slate-900 text-sm">
                                {performancePlace.coupon_1_subCategory?.name}
                            </Text>
                        </View>

                        {(performancePlace.coupon_2_isActive && performancePlace.coupon_1_subCategory?.name !== performancePlace.coupon_2_subCategory?.name) && (
                            <View
                                style={{
                                    borderRadius: 30
                                }}
                                className="bg-slate-50 rounded-xl ml-1.5"
                            >
                                <Text className="mx-auto items-center py-1 px-4 font-normal  text-center  text-slate-900 text-sm">
                                    {performancePlace.coupon_2_subCategory?.name}
                                </Text>
                            </View>
                        )}
                    </View>

                </View>
            </ScrollView>

        </View>
    )
}